<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <wiki slug="services-domains-overview" />
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="type"
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.status="{ item }">
          {{ $t("status_" + item.status) }}
        </template>

        <template v-slot:item.domain_status="{ item }">
          {{ $t("domStatus_" + item.domain_status) }}
        </template>

        <template v-slot:item.order_date="{ item }">
          <div class="monospace">
            {{ item.order_date == null ? "" : formatDateTime(item.order_date) }}
          </div>
        </template>

        <template v-slot:item.paid_until_date="{ item }">
          <div class="monospace">
            {{
              item.paid_until_date == null
                ? ""
                : formatDate(item.paid_until_date)
            }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'domain-service-details',
                    params: { domainId: item.id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open domain details") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "DomainDns",
  mixins: [formatDateTime, formatDate, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    domainName: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    Wiki,
  },
  data: () => ({
    items: [],
    loading: false,
    search: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("domain name"),
          value: "domain_name",
        },
        {
          text: this.$i18n.t("status"),
          value: "status",
        },
        {
          text: this.$i18n.t("domain status"),
          value: "domain_status",
        },
        {
          text: this.$i18n.t("order date"),
          value: "order_date",
        },
        {
          text: this.$i18n.t("paid until"),
          value: "paid_until_date",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
  },
  methods: {
    customFilter(value, search, item) {
      return (
        (item.domain_name != null && item.domain_name.indexOf(search) >= 0) ||
        (item.reseller_id != null && item.reseller_id.indexOf(search) >= 0)
      );
    },
    getItems: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/dns/registry", {
          params: {
            product_id: this.productId,
            domain_name: this.domainName,
          },
        })
        .then((response) => {
          that.items = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "search": "Search",
    "status": "Status",
    "domain name": "Domain name",
    "domain status": "Domain status",
    "order date": "Order date",
    "paid until": "Paid until",
    "reseller ID": "Reseller ID",
    "open domain details": "open domain details",

    "status_kk_laeuft": "Transfer is running",
    "status_kk_nack": "Transfer failed",
    "status_kk_nack_code_falsch": "Transfer failed: invalid authentication code",
    "status_kk_nack_denied": "Transfer was declined",
    "status_kk_nack_dns_falsch": "Transfer failed: nameserver error",
    "status_kk_nsfehler": "Transfer failed: nameserver error",
    "status_kk_ok": "Transfer successfully completed",
    "status_kk_ok_com": "Transfer successfully completed: no subsequent update",
    "status_kk_und_update_ok": "Transfer and update successfully completed",
    "status_loeschung_laeuft_off": "Domain deletion has been initiated",
    "status_loeschung_off": "Domain has been deleted",
    "status_on_hold_off": "Domain in transit",
    "status_reg_exists": "Registration failed: domain already exists",
    "status_reg_laeuft": "Registration has started",
    "status_reg_nack": "Registration failed",
    "status_reg_nack_dns_falsch": "Registration failed due to a name server error",
    "status_reg_nsfehler": "Registration failed due to a name server error",
    "status_reg_ok": "Registration completed successfully",
    "status_starttermin_order": "Register / transfer on a specific date",
    "status_vorreg2_order": "Binding reservation",
    "status_vorreg_order": "Non-binding reservation",
    "status_zu_fremdprovider_uebernommen_off": "Domain was transferred to another provider",
    "status_update_ok": "Update successful",
    "status_update_laeuft": "Update is running",
    "status_update_nack_wait": "Update refused, try again",
    "status_update_nack": "Update failed",
    "status_update_nsfehler": "Name server update failed",
    "status_processing": "Processing",

    "domStatus_scheduledReg": "Domain is reserved for registration",
    "domStatus_scheduledTransfer": "Domain is reserved for the transfer",
    "domStatus_pendingReg": "Domain is being registered",
    "domStatus_pendingTransfer": "Domain is currently being transferred to our system",
    "domStatus_active": "Domain is in your active inventory",
    "domStatus_failed": "Domain was registered / transferred with (name server) errors. Depending on how the registry is used, the domain may be deleted",
    "domStatus_pendingDelete": "Domain is being deleted",
    "domStatus_deleted": "Domain was deleted",
    "domStatus_pendingTransit": "Domain is currently being put into transit",
    "domStatus_transit": "Domain was put in transit",
    "domStatus_transferredOut": "Domain was moved to another registrar",
    "domStatus_failedTransfer": "Domain could not be transferred successfully",
    "domStatus_failedReg": "Domain could not be registered successfully",
    "domStatus_unknown": "The status cannot currently be determined, please contact support"
  }, 
  "de": {
    "search": "Suche",
    "status": "Status",
    "domain name": "Domain Name",
    "domain status": "Domain Status",
    "order date": "Bestelldatum",
    "paid until": "Bezahlt bis",
    "reseller ID": "Reseller ID",
    "open domain details": "öffne Domain Details",

    "status_kk_laeuft": "Transfer läuft",
    "status_kk_nack": "Transfer fehlgeschlagen",
    "status_kk_nack_code_falsch": "Transfer fehlgeschlagen: AuthCode falsch",
    "status_kk_nack_denied": "Transfer wurde abgelehnt",
    "status_kk_nack_dns_falsch": "Transfer fehlgeschlagen Nameserverfehler",
    "status_kk_nsfehler": "Transfer fehlgeschlagen Nameserverfehler",
    "status_kk_ok": "Transfer erfolgreich abgeschlossen",
    "status_kk_ok_com": "Transfer erfolgreich abgeschlossen: kein nachfolgendes Update",
    "status_kk_und_update_ok": "Transfer und Update erfolgreich abgeschlossen",
    "status_loeschung_laeuft_off": "Domain-Löschung wurde eingeleitet",
    "status_loeschung_off": "Domain wurde gelösch",
    "status_on_hold_off": "Domain in Transit",
    "status_reg_exists": "Registrierung fehlgeschlagen: Domain existiert bereits",
    "status_reg_laeuft": "Registrierung wurde eingeleitet",
    "status_reg_nack": "Registrierung fehlgeschlagen",
    "status_reg_nack_dns_falsch": "Registrierung aufgrund eines Nameserverfehlers fehlgeschlagen",
    "status_reg_nsfehler": "Registrierung aufgrund eines Nameserverfehlers fehlgeschlagen",
    "status_reg_ok": "Registrierung erfolgreich abgeschlossen",
    "status_starttermin_order": "Registrieren / Transferieren zu einem bestimmten Termin",
    "status_vorreg2_order": "Verbindliche Vormerkung",
    "status_vorreg_order": "Unverbindliche Vormerkung",
    "status_zu_fremdprovider_uebernommen_off": "Domain wurde zu einem anderen Provider übertragen",
    "status_update_ok": "Update erfolgreich",
    "status_update_laeuft": "Update läuft",
    "status_update_nack_wait": "Update abgelehnt, erneut versuchen",
    "status_update_nack": "Update fehlgeschlagen",
    "status_update_nsfehler": "Nameserver-Update fehlgeschlagen",
    "status_processing": "In Bearbeitung",
   
    "domStatus_scheduledReg": "Domain ist zur Registrierung vorgemerkt",
    "domStatus_scheduledTransfer": "Domain ist für den Transfer vorgemerkt",
    "domStatus_pendingReg": "Domain wird gerade registriert",
    "domStatus_pendingTransfer": "Domain wird gerade in unser System transferiert",
    "domStatus_active": "Domain befindet sich in Ihrem aktiven Bestand",
    "domStatus_failed": "Domain wurde mit (Nameserver-) Fehlern registriert/transferiert. Je nach Bestimmung der Registry kann es zu einer Löschung der Domain kommen",
    "domStatus_pendingDelete": "Domain wird gerade gelöscht",
    "domStatus_deleted": "Domain wurde gelöscht",
    "domStatus_pendingTransit": "Domain wird gerade in den Transit gegeben",
    "domStatus_transit": "Domain wurde in den Transit gegeben",
    "domStatus_transferredOut": "Domain wurde zu einem anderen Registrar weggezogen",
    "domStatus_failedTransfer": "Domain konnte nicht erfolgreich transferiert werden",
    "domStatus_failedReg": "Domain konnte nicht erfolgreich registriert werden",
    "domStatus_unknown": "Der Status kann aktuell nicht bestimmt werden, bitte wenden Sie sich an den Support"
  }
}
</i18n>
