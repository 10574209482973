var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),_c('wiki',{attrs:{"slug":"services-domains-overview"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"search":_vm.search,"custom-filter":_vm.customFilter,"sort-by":"type","hide-default-footer":_vm.items.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t("status_" + item.status))+" ")]}},{key:"item.domain_status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t("domStatus_" + item.domain_status))+" ")]}},{key:"item.order_date",fn:function({ item }){return [_c('div',{staticClass:"monospace"},[_vm._v(" "+_vm._s(item.order_date == null ? "" : _vm.formatDateTime(item.order_date))+" ")])]}},{key:"item.paid_until_date",fn:function({ item }){return [_c('div',{staticClass:"monospace"},[_vm._v(" "+_vm._s(item.paid_until_date == null ? "" : _vm.formatDate(item.paid_until_date))+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'domain-service-details',
                  params: { domainId: item.id },
                })}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("open domain details")))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }